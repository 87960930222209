import React, { Component, useState } from 'react';
import * as TicketsSDK1 from 'tickets-sdk';
import QueueMapper from './QueueMapper';
import languages from './languages.json';
import unitedKingdom from './../img/united-kingdom.svg';
import portugal from './../img/portugal.svg';
import france from './../img/france.svg';
import italy from './../img/italy.svg';
import spain from './../img/spain.svg';
import checked from './../img/checked.svg';
import unChecked from './../img/unchecked.svg';
import settingsSVG from './../img/settings.svg';
import person from './../img/person.svg';
import navigate from './../img/navigate.svg';
import language from './../img/language.svg';
import add from './../img/add.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OfflineQueue from './OfflineQueue';

const TicketsSDK = TicketsSDK1.default.TicketsSDK;
const serverUrl = process.env.REACT_APP_SERVERURL;
const tickets = new TicketsSDK(serverUrl);

class Queues extends Component {
  constructor() {
    super();

    this.state = {
      queues: [],
      idioma: '',
      image: '',
      table: '',
      availableTables: [],
      selectedOption: '',
      selectedOptionIdioma: '',
      offline: false,
      nextQueue: '',
    };
    this.ModalTable = this.ModalTable.bind(this);
    this.ModalSettings = this.ModalSettings.bind(this);
    this.ModalIdioma = this.ModalIdioma.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.formSubmitIdioma = this.formSubmitIdioma.bind(this);
    this.onValueChangeIdioma = this.onValueChangeIdioma.bind(this);
  }

  handler = (val) => {
    this.setState({
      nextQueue: val
    })
  }

  async setupWithSDK() {
    const queues = [];
    const size = localStorage.getItem('@tickets-pwa/queueId-size');
    for (let i = 1; i <= size; i++) {
      let id = localStorage.getItem('@tickets-pwa/queueId/' + i);
      try {
        let newQueue = await tickets.Queue.getOneByIdV2(id);
        if (newQueue.status === 200) {
          queues.push(newQueue.data);
          localStorage.setItem('@tickets-pwa/queueName/' + i, newQueue.data.name);
        }
      } catch (e) {
        console.log(e);
        this.setState({ offline: true })
      }
    }

    this.setState({ queues });
    this.setState({ image: portugal });

    try {
      const body = { queues: queues.map(item => item._id) }
      const queue = await tickets.Queue.getNextQueue(body);
      this.setState({
        nextQueue: queue.name ? queue.name : ''
      })
    } catch (e) {
      console.log(e);
    }
    const defaultLanguage = localStorage.getItem('@tickets-pwa/language');
    this.setState({ idioma: defaultLanguage ? defaultLanguage : 'pt' });
    this.setState({ selectedOptionIdioma: defaultLanguage ? defaultLanguage : 'pt' });

    const teste = await tickets.Table.requestTablesByQueues({ queues });
    if (teste.length > 0) {
      const tables = teste.map(item => {
        return item._id;
      });
      this.setState({ availableTables: teste });
      const selectedTable = localStorage.getItem('@tickets-pwa/table');
      if (selectedTable) {
        this.setState({ table: selectedTable });
        this.setState({ selectedOption: selectedTable });
      } else {
        localStorage.setItem('@tickets-pwa/table', tables[0]);
        this.setState({ selectedOption: tables[0] });
        this.setState({ table: tables[0] });
      }
    }
  }

  async componentDidMount() {
    await this.setupWithSDK();
  }

  navigate(lang) {
    window.location.href = '/add?lang=' + lang;
  }

  formSubmit(event) {
    event.preventDefault();
    this.setState({ table: this.state.selectedOption });
    localStorage.setItem('@tickets-pwa/table', this.state.selectedOption);
    window.location.reload();
  }

  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value
    });
  }

  formSubmitIdioma(event) {
    event.preventDefault();
    this.setState({ idioma: this.state.selectedOptionIdioma });
    localStorage.setItem('@tickets-pwa/language', this.state.selectedOptionIdioma);
  }

  onValueChangeIdioma(event) {
    this.setState({
      selectedOptionIdioma: event.target.value
    });
  }

  ModalTable() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const tables = this.state.availableTables;
    const idioma = this.state.idioma;

    const radio = [];
    for (var i = 0; i < tables.length; i++) {
      radio.push(
        <div className="radio2" key={i}>
          <div className="balcao">
            {languages['balcao'][idioma]} {tables[i].name}{' '}
          </div>
          <div className="radio-option">
            <label>
              <input
                type="radio"
                value={tables[i]._id}
                checked={this.state.selectedOption === tables[i]._id}
                onChange={this.onValueChange}
              />
              {this.state.selectedOption === tables[i]._id ? (
                <img alt="" src={checked} />
              ) : (
                  <img alt="" src={unChecked} />
                )}
            </label>
          </div>
        </div>
      );
    }
    if (radio.length <= 0) {
      radio.push(
        <div className="radio2" key={1}>
          <label>
            <div className="balcao-text">{languages['vazio2'][idioma]}</div>
          </label>
        </div>
      );
    }
    return (
      <>
        <div className="modal-settings-button" onClick={handleShow}>
          <div className="left-img">
            <img alt="" src={person} />
          </div>
          <div className="middle-text">{languages['balcao'][idioma]}</div>
          <div className="right-img">
            <img alt="" src={navigate} />
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          bsPrefix="modalt"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>{languages['selecionarBalcao'][idioma]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.formSubmit}>
              {radio}

              {this.state.selectedOption ? (
                <div className="modal-center-blue">
                  <Button onClick={handleClose} type="submit">
                    {languages['confirmar'][idioma]}
                  </Button>
                </div>
              ) : (
                  <div className="modal-center">
                    <Button onClick={handleClose}>{languages['cancela'][idioma]}</Button>
                  </div>
                )}
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  ModalIdioma() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const idioma = this.state.idioma;
    const idiomas = ['en', 'pt', 'fr', 'es', 'it'];
    const images = [unitedKingdom, portugal, france, spain, italy];
    const teste = ['Inglês', 'Português', 'Francês', 'Espanhol', 'Italiano'];

    const radio = [];
    for (var i = 0; i < idiomas.length; i++) {
      radio.push(
        <div className="radio2" key={i}>
          <div className="flag">
            <img alt="" src={images[i]} />
          </div>
          <div className="balcao2"> {teste[i]} </div>
          <div className="radio-option">
            <label>
              <input
                type="radio"
                value={idiomas[i]}
                checked={this.state.selectedOptionIdioma === idiomas[i]}
                onChange={this.onValueChangeIdioma}
              />
              {this.state.selectedOptionIdioma === idiomas[i] ? (
                <img alt="" src={checked} />
              ) : (
                  <img alt="" src={unChecked} />
                )}
            </label>
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="modal-settings-button" onClick={handleShow}>
          <div className="left-img">
            <img alt="" src={language} />
          </div>
          <div className="middle-text">{languages['idioma'][idioma]}</div>
          <div className="right-img">
            <img alt="" src={navigate} />
          </div>
        </div>

        <Modal
          show={show}
          bsPrefix="modalt"
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>{languages['idioma'][idioma]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.formSubmitIdioma}>
              {radio}

              {this.state.selectedOptionIdioma ? (
                <div className="modal-center-blue">
                  <Button onClick={handleClose} type="submit">
                    {languages['confirmar'][idioma]}
                  </Button>
                </div>
              ) : (
                  <div className="modal-center">
                    <Button onClick={handleClose}>{languages['cancela'][idioma]}</Button>
                  </div>
                )}
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  ModalSettings() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const idioma = this.state.idioma;

    return (
      <>
        <img alt="" src={settingsSVG} className="add" onClick={handleShow} />

        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <Modal.Title>{languages['definicoes'][idioma]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="radio3">
              <div className="table-settings">
                <this.ModalTable />
              </div>
              <div className="idioma-settings">
                <this.ModalIdioma />
              </div>
            </div>
            <div className="modal-center">
              <Button onClick={handleClose}>{languages['cancela'][idioma]}</Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  render() {
    const idioma = this.state.idioma;
    const queues = this.state.queues;
    const isOffline = this.state.offline;
    return (
      <div className="queue--section container">
        <div className="top-header">
          <div className="top-settings">
            <div className="top-button-middle">
              <this.ModalSettings />
            </div>
          </div>
          <div className="top-title">{languages['nome'][idioma]}</div>
          <div className="top-button">
            <div className="top-button-middle">
              <div className="Oval">
                <img alt="" src={add} className="add" onClick={() => this.navigate(idioma)} />
              </div>
            </div>
          </div>
        </div>
        <div className="next-queue">{languages['next'][idioma]}: {this.state.nextQueue}
        </div>
        {queues.length > 0 ? (
          <QueueMapper entries={this.state.queues} entry={idioma} handler={this.handler} />
        ) : (
            isOffline ? (<OfflineQueue />) :
              (<h1>{languages['vazio'][idioma]}</h1>)
          )}
      </div>
    );
  }
}

export default Queues;
