import React, { Component, useState } from 'react';
import './queue.css';
import * as TicketsSDK1 from 'tickets-sdk';
import btnRight from './../img/btn-right.svg';
import btnLeft from './../img/btn-left.svg';
import bonecoMaisPequeno from './../img/bonecoMaisPequeno.svg';
import reiniciar from './../img/reiniciar.svg';
import dots from './../img/dots.svg';
import cancel from './../img/cancel.svg';
import lixo from './../img/lixo.svg';
import semSenhas from './../img/sem-senhas.svg';
import add from './../img/add.svg';
import alerta from './../img/alerta.svg';
import btnLeftDisabled from './../img/btn-left-disabled.svg';
import btnRightDisabled from './../img/btn-right-disabled.svg';
import cherry from './../img/cherry-no-comments.png';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import languages from './languages.json';
import { connectSocket, listenNewTicketCallBack, listenShiftOrUndoCallBack } from './api';

const TicketsSDK = TicketsSDK1.default.TicketsSDK;
const serverUrl = process.env.REACT_APP_SERVERURL;
const tickets = new TicketsSDK(serverUrl);

class OneQueue extends Component {
  constructor(props) {
    super(props);

    this.state = { queue: {}, queues: {}, ticket: {}, showComponent: false, table: '', queueName: '' };
    this.Example = this.Example.bind(this);
    this._onButtonClick = this._onButtonClick.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleData2 = this.handleData2.bind(this);
  }

  _onButtonClick(op) {
    this.setState({
      showComponent: op
    });
  }

  async updateTicketName(queue) {
    const ticket = await tickets.Tickets.getByQueueAndPosition(queue._id, queue.currentQueuePosition);
    this.setState({ ticket });
  }

  async getNextTicketSuggestion() {
    const queues = this.state.queues.map(item => item._id);
    const body = { queues }
    const queue = await tickets.Queue.getNextQueue(body);

    if (queue) {
      return queue.name;
    } else {
      return 0;
    }
  }

  async handleNext(queueId, table) {
    this._onButtonClick(false);
    const result = await tickets.Queue.shift(queueId, 'Valid', table);
    if (result.Status === 'No ticket in Queue!') {
    } else {
      const queue = await tickets.Queue.getOneById(queueId);
      this.setState({ queue });
      await this.updateTicketName(queue);

      const queueName = await this.getNextTicketSuggestion();
      if (queueName !== 0) {
        this.props.handler(queueName);
      } else {
        this.props.handler('');
      }
    }
  }

  async handleUndo(queue, table) {
    if (queue.lastTicketNumber === 0) {
    } else {
      const undoResult = await tickets.Queue.undo(queue._id, table);
      if (undoResult._id) {
        queue = await tickets.Queue.getOneById(queue._id);
        this.setState({ queue });
        await this.updateTicketName(queue);
        const queueName = await this.getNextTicketSuggestion();
        if (queueName !== 0) {
          this.props.handler(queueName);
        } else {
          this.props.handler('');
        }
      } else {
        this._onButtonClick(true);
      }
    }
  }

  async componentDidMount() {
    await connectSocket(serverUrl);
    const queue = this.props.entrie;
    this.setState({ queue });
    const queues = this.props.queues;
    this.setState({ queues });
    const setTable = localStorage.getItem('@tickets-pwa/table');

    if (setTable) {
      this.setState({ table: setTable });
    } else {
      const teste = await tickets.Table.requestTablesByQueues({ queues: [queue._id] });
      if (teste.length > 0) {
        this.setState({ table: teste[0]._id });
      }
    }
    await this.updateTicketName(this.props.entrie);

    if (queue._id) {
      listenNewTicketCallBack(queue._id, this.handleData);
      listenShiftOrUndoCallBack(queue._id, this.handleData2);
    }
  }

  async handleData(data) {
    const updatedQueue = await tickets.Queue.getOneById(data.queueId);
    this.setState({ queue: updatedQueue });
    // if (this.state.queueName === '') {
    const queueName = await this.getNextTicketSuggestion();
    if (queueName !== 0) {
      this.props.handler(queueName);
    }
    // }
  }

  async handleData2(data) {
    const updatedQueue = await tickets.Queue.getOneById(data.QueueId);
    this.setState({ queue: updatedQueue });
    const queueName = await this.getNextTicketSuggestion();
    if (queueName !== 0) {
      this.props.handler(queueName);
    } else {
      this.props.handler('');
    }
    if (data.action === 'shift') {
      this._onButtonClick(false);
    }
  }

  async handleReset(queue) {
    await tickets.Queue.reset(queue._id, queue.token);
    queue = await tickets.Queue.getOneById(queue._id);
    this.setState({ queue });
  }

  async handlePickTicket(queueId, name, phone) {
    await tickets.Queue.createTicket(queueId, { name, phone });
  }

  async handleRemove(queueId) {
    const size = localStorage.getItem('@tickets-pwa/queueId-size');
    for (let i = 1; i <= size; i++) {
      let id = localStorage.getItem('@tickets-pwa/queueId/' + i);
      if (id === queueId) {
        for (let j = i; j <= size - 1; j++) {
          let b = j + 1;
          let id2 = localStorage.getItem('@tickets-pwa/queueId/' + b);
          localStorage.setItem('@tickets-pwa/queueId/' + j, id2);
          let name = localStorage.getItem('@tickets-pwa/queueName/' + b);
          localStorage.setItem('@tickets-pwa/queueName/' + j, name);
        }
        localStorage.removeItem('@tickets-pwa/queueId/' + size);
        localStorage.removeItem('@tickets-pwa/queueName/' + size);
        let newSize = parseInt(size) - 1;
        localStorage.setItem('@tickets-pwa/queueId-size', newSize);
        window.location.reload(false);
        return true;
      }
    }
    return false;
  }

  Example() {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const queue = this.state.queue;
    const idioma = this.props.entry;

    const [validated, setValidated] = useState(false);

    const handleSubmit = async event => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        event.preventDefault();
        const name = form.formBasicName.value;
        const number = form.formBasicNumber.value;
        await this.handlePickTicket(queue._id, name, number);
        window.location.href = '/';
      }

      setValidated(true);
    };

    return (
      <>
        <img alt="" src={dots} className="Shape" onClick={handleShow} />

        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <Modal.Title>{queue.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-center">
              <Button onClick={handleShow3}>
                <img alt="" src={add} className="reiniciar" />
                {languages['tirarSenha'][idioma]}
              </Button>
            </div>
            <div className="modal-center">
              <Button
                onClick={() => {
                  this.handleReset(queue);
                }}
              >
                <img alt="" src={reiniciar} className="reiniciar" />
                {languages['reiniciar'][idioma]}
              </Button>
            </div>

            <div className="modal-center2">
              <Button onClick={handleShow2}>
                <img alt="" src={lixo} className="reiniciar" />
                {languages['remover'][idioma]}
              </Button>
            </div>
            <div className="modal-footer2">
              <Button onClick={handleClose}>
                <img alt="" src={cancel} className="reiniciar" />
                {languages['cancelar'][idioma]}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show2}
          onHide={handleClose2}
          size="lg"
          dialogClassName="modal-content2"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <img alt="" src={cherry} className="cherry-no-comments" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-question">
              <Modal.Title>{languages['pergunta'][idioma]}</Modal.Title>
            </div>
            <div className="modal-warning">{languages['frase1'][idioma]}</div>
            <div className="modal-final">
              <Button className="btn-cancel" onClick={handleClose2}>
                {languages['cancela'][idioma]}
              </Button>
              <Button className="btn-close" onClick={() => this.handleRemove(queue._id)}>
                {languages['remover'][idioma]}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={show3}
          onHide={handleClose3}
          size="lg"
          dialogClassName="modal-content2"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <div className="title-ticket">{languages['tirarSenha'][idioma]}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label>{languages['name2'][idioma]}</Form.Label>
                <Form.Control required type="text" placeholder={languages['name2'][idioma]} />
                {/* <Form.Control.Feedback type="invalid">{languages['validarNome'][idioma]}</Form.Control.Feedback> */}
              </Form.Group>

              <Form.Group controlId="formBasicNumber">
                <Form.Label>{languages['numero2'][idioma]}</Form.Label>
                <Form.Control type="text" placeholder={languages['numero2'][idioma]} />
                <Form.Text className="text-muted">{languages['optional'][idioma]}</Form.Text>
              </Form.Group>
              <div className="modal-final">
                <Button className="btn-cancel" onClick={handleClose3}>
                  {languages['cancela'][idioma]}
                </Button>
                <Button type="submit" className="btn-confirm">
                  {languages['confirmar'][idioma]}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  render() {
    const queue = this.state.queue;
    const ticket = this.state.ticket;
    const idioma = this.props.entry;
    const table = this.state.table ? this.state.table : '{table}';

    return (
      <div key={queue._id} className="queueData">

        <div className="queueData-title">
          {queue.name}
          <this.Example />
        </div>
        <div className="queueData-buttons">
          <div className="queueData-button-left">
            {queue.lastTicketNumber > 0 ? (
              <img alt="" src={btnLeft} className="Shape" onClick={() => this.handleUndo(queue, table)} />
            ) : (
                <img alt="" src={btnLeftDisabled} className="Shape" />
              )}
          </div>
          <div className="queueData-button-middle">
            <div className="queueData-button-middle-text">
              <span className="span1">{queue.currentQueuePosition}</span>
              <span className="span2"> /{queue.lastTicketNumber}</span>
            </div>
          </div>
          <div className="queueData-button-right">
            {queue.lastTicketNumber > 0 ? (
              <img alt="" src={btnRight} className="Shape" onClick={() => this.handleNext(queue._id, table)} />
            ) : (
                <img alt="" src={btnRightDisabled} className="Shape" />
              )}
          </div>
        </div>
        <div className="queueData-buttons2">
          <div className="queueData-button-left2">
            {queue.lastTicketNumber > 0 ? (
              <img alt="" src={bonecoMaisPequeno} className="ShapeBoneco" />
            ) : (
                <img alt="" src={semSenhas} className="Shape" />
              )}
          </div>
          <div className="queueData-button-middle2">
            <div className="queueData-button-middle-text2">
              {queue.lastTicketNumber > 0 ? (
                ticket.name
              ) : (
                  <div className="queueData-semSenhas">{languages['erroNaoExistemSenhas'][idioma]}</div>
                )}
            </div>
          </div>
          <div className="queueData-button-right2">
            <div className="Shape"></div>
          </div>
        </div>
        {this.state.showComponent ? (
          <div className="queueData-buttons3">
            <div className="queueData-button-left2">
              <img alt="" src={alerta} className="Shape" />
            </div>
            <div className="queueData-button-middle2">
              <div className="queueData-button-middle-text2">
                <div className="queueData-semSenhas">{languages['erroTolerancia'][idioma]}</div>
              </div>
            </div>
            <div className="queueData-button-right2">
              <div className="Shape"></div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default OneQueue;
