import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import * as jwtDecode from 'jwt-decode';

class QrCamera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: 'No result',
      name: '',
      showComponent: false
    };
  }

  handleScan = data => {
    const handleToUpdate = this.props.handleToUpdate;
    let queueId;
    let name;
    if (data) {
      try {
        let token = jwtDecode(data);
        queueId = token.sub;
        name = token.username;
      } catch (e) {
        handleToUpdate('someVar');
      }
      this.setState({
        result: queueId,
        name
      });
      this.handleAddQueue();
    }
  };
  handleError = err => {
    console.error(err);
  };

  canAddQueue() {
    if (this.state.result === 'No result') {
      return false;
    }
    const size = localStorage.getItem('@tickets-pwa/queueId-size');
    for (let i = 1; i <= size; i++) {
      let id = localStorage.getItem('@tickets-pwa/queueId/' + i);
      if (id === this.state.result) {
        return false;
      }
    }
    return true;
  }

  async handleAddQueue() {
    const handleToUpdate = this.props.handleToUpdate;
    if (!(this.state.result === undefined)) {
      const size = localStorage.getItem('@tickets-pwa/queueId-size');

      if (size === null) {
        localStorage.setItem('@tickets-pwa/queueId-size', 1);
        localStorage.setItem('@tickets-pwa/queueId/1', this.state.result);
        localStorage.setItem('@tickets-pwa/queueName/1', this.state.name);
      } else {
        if (this.canAddQueue()) {
          let newSize = parseInt(size) + 1;

          localStorage.setItem('@tickets-pwa/queueId-size', newSize);
          localStorage.setItem('@tickets-pwa/queueId/' + newSize, this.state.result);
          localStorage.setItem('@tickets-pwa/queueName/' + newSize, this.state.name);
        } else {
          handleToUpdate('someVar');
        }
      }
      window.location.href = '/';
    }
  }

  render() {
    return (
      <div>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%', height: '428px' }}
          className="testee"
        />
      </div>
    );
  }
}

export default QrCamera;
