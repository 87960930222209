import openSocket from 'socket.io-client';

let socket;

async function connectSocket(server) {
  const url = server;
  socket = openSocket(url);

  socket.on('disconnect', () => {
    console.log('disconnect close');
  });
}

function listenNewTicketCallBack(queueId, callback) {
  socket.on('newTicket/' + queueId, callback);
}
function listenShiftOrUndoCallBack(queueId, callback) {
  socket.on('queueId/' + queueId, callback);
}

export { connectSocket, listenNewTicketCallBack, listenShiftOrUndoCallBack };
