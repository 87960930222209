import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Queues from './Queue/Queues';

function App() {
  return (
    <div className="">
      <section className="results--section">
        <div className="container">
          <Queues />
        </div>
        <div className="results--section__inner"></div>
      </section>
    </div>
  );
}

export default App;
