import React, { Component } from 'react';
import './queue.css';
import semSenhas from './../img/sem-senhas.svg';
import btnLeftDisabled from './../img/btn-left-disabled.svg';
import btnRightDisabled from './../img/btn-right-disabled.svg';


class OfflineQueue extends Component {
    render() {
        const size = localStorage.getItem('@tickets-pwa/queueId-size');
        let offlineQueues = [];

        for (let i = 1; i <= size; i++) {
            offlineQueues.push(
                <div className="queueData">
                    <div className="queueData-title">
                        {localStorage.getItem('@tickets-pwa/queueName/' + i)} (Offline)
                    </div>
                    <div className="queueData-buttons">
                        <div className="queueData-button-left">
                            <img alt="" src={btnLeftDisabled} className="Shape" />
                        </div>
                        <div className="queueData-button-middle">
                            <div className="queueData-button-middle-text">
                                <span className="span1">0</span>
                                <span className="span2">/0</span>
                            </div>
                        </div>
                        <div className="queueData-button-right">
                            <img alt="" src={btnRightDisabled} className="Shape" />
                        </div>
                    </div>
                    <div className="queueData-buttons2">
                        <div className="queueData-button-left2">
                            <img alt="" src={semSenhas} className="Shape" />
                        </div>
                        <div className="queueData-button-middle2">
                            <div className="queueData-button-middle-text2">
                                <div className="queueData-semSenhas">Offline</div>
                            </div>
                        </div>
                        <div className="queueData-button-right2">
                            <div className="Shape"></div>
                        </div>
                    </div>
                </div>
            )
        }

        return <div className="theList">{offlineQueues}</div>;
    }
}

export default OfflineQueue;
