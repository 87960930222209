import React, { Component } from 'react';
import './queue.css';
import OneQueue from './OneQueue';

class QueueMapper extends Component {
  constructor(props) {
    super(props);

    this.state = { queues: [] };
  }

  createQueue = queue => {
    const queues = this.props.entries;
    const idioma = this.props.entry;
    return <OneQueue entrie={queue} key={queue._id} entry={idioma} queues={queues} handler={this.props.handler} />;
  };

  render() {
    const queues = this.props.entries;
    const listItems = queues.map(this.createQueue);

    return <div className="theList">{listItems}</div>;
  }
}

export default QueueMapper;
