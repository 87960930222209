import React, { Component, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Queue/queue.css';
import cherry from './../img/cherry-welcome.png';
import cherry46 from './../img/cherry-46.png';
import qr from './../img/white-qr.svg';
import path from './../img/path.svg';
import setaDisable from './../img/setaDisable.svg';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import QrCamera from './QrCamera';
import languages from './languages.json';
import * as TicketsSDK1 from 'tickets-sdk';

const TicketsSDK = TicketsSDK1.default.TicketsSDK;
const serverUrl = process.env.REACT_APP_SERVERURL;
const tickets = new TicketsSDK(serverUrl);

class AddQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      result: 'No result',
      name: '',
      showComponent: false,
      showComponent2: false,
      alerted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this._onButtonClick = this._onButtonClick.bind(this);
    this._onButtonClick2 = this._onButtonClick2.bind(this);
    this.updateAlertState = this.updateAlertState.bind(this);
    this.Example = this.Example.bind(this);
    this.AlertDismissibleExample = this.AlertDismissibleExample.bind(this);
  }

  detectWebcam(callback) {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then(devices => {
      callback(devices.some(device => 'videoinput' === device.kind));
    });
  }

  async componentDidMount() {
    this.detectWebcam(hasWebcam => {
      this.setState({ hasWebcam: hasWebcam ? true : false });
    });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  _onButtonClick(op) {
    this.setState({
      showComponent: op
    });
  }

  _onButtonClick2(op) {
    this.setState({
      showComponent2: op
    });
  }

  async handleScan(result) {
    let queueId;
    let name;
    if (result) {
      try {
        const checkQueue = await tickets.Queue.findOneByCode(result);
        if (checkQueue === '' || checkQueue === undefined) {
          throw new Error('undefined');
        }
        queueId = checkQueue._id;
        name = checkQueue.name;
      } catch (e) {
        this._onButtonClick(true);
      }
      this.setState({ result: queueId, name });
    }
  }

  canAddQueue() {
    if (this.state.result === 'No result') {
      return false;
    }
    const size = localStorage.getItem('@tickets-pwa/queueId-size');
    for (let i = 1; i <= size; i++) {
      let id = localStorage.getItem('@tickets-pwa/queueId/' + i);
      if (id === this.state.result) {
        return false;
      }
    }
    return true;
  }

  async handleAddQueue() {
    await this.handleScan(this.state.value);
    if (!(this.state.value === '')) {
      if (!(this.state.result === undefined)) {
        const size = localStorage.getItem('@tickets-pwa/queueId-size');

        if (size === null) {
          localStorage.setItem('@tickets-pwa/queueId-size', 1);
          localStorage.setItem('@tickets-pwa/queueId/1', this.state.result);
          localStorage.setItem('@tickets-pwa/queueName/1', this.state.name);
          window.location.href = '/';
        } else {
          if (this.canAddQueue()) {
            let newSize = parseInt(size) + 1;

            localStorage.setItem('@tickets-pwa/queueId-size', newSize);
            localStorage.setItem('@tickets-pwa/queueId/' + newSize, this.state.result);
            localStorage.setItem('@tickets-pwa/queueName/' + newSize, this.state.name);
            window.location.href = '/';
          } else {
            this._onButtonClick2(true);
          }
        }
      }
    }
  }

  AlertDismissibleExample(option) {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const idioma = params.get('lang');

    return (
      <Alert
        variant="danger"
        onClose={() => {
          this._onButtonClick(false);
          this._onButtonClick2(false);
        }}
        dismissible
      >
        <Alert.Heading>{languages[option.option][idioma]}</Alert.Heading>
      </Alert>
    );
  }

  navigate() {
    window.location.href = '/';
  }

  updateAlertState(op) {
    this.setState({
      alerted: op
    });
  }

  Example(entry) {
    const [show, setShow] = useState(false);
    const [alert, setAlert] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAlert = () => setAlert(true);

    const handleToUpdate = someArg => {
      handleClose();
      handleAlert();
      this.updateAlertState(true);
    };
    return (
      <>
        {alert ? (
          <Button onClick={handleShow} className="btn-reler">
            <img alt="" src={qr} className="reiniciar" />
            {languages['lerNovamente'][entry.entry]}
          </Button>
        ) : (
            <Button onClick={handleShow}>
              <img alt="" src={qr} className="reiniciar" />
              {languages['ler'][entry.entry]}
            </Button>
          )}

        <Modal
          dialogClassName="modal-content2"
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <QrCamera entry={entry.entry} handleToUpdate={handleToUpdate} />
          </Modal.Header>
          <Modal.Body></Modal.Body>
        </Modal>
      </>
    );
  }

  render() {
    const search = this.props.location.search; // returns the URL query String
    const params = new URLSearchParams(search);
    const idioma = params.get('lang');
    let hasWebcam = this.state.hasWebcam;
    const alerted = this.state.alerted;

    return (
      <div className="">
        <section className="results--section">
          <div className="container">
            <div className="queue--section container">
              <img alt="" src={path} className="Path2" onClick={() => this.navigate()} />
              <div className="clown">
                {alerted ? (
                  <img alt="" src={cherry46} className="cherry-welcome" />
                ) : (
                    <img alt="" src={cherry} className="cherry-welcome" />
                  )}
              </div>
              {alerted ? (
                <div className="add-title">{languages['erroCodigo'][idioma]}</div>
              ) : (
                  <div className="add-title">{languages['adicionar'][idioma]}</div>
                )}

              {hasWebcam ? (
                <div>
                  <div className="add-QR">
                    <this.Example entry={idioma} />
                  </div>
                  <div className="add-qr">{languages['frase2'][idioma]}</div>
                  <div className="add-qr2">{languages['ou'][idioma]}</div>
                </div>
              ) : null}

              <div className="add-qr">{languages['frase3'][idioma]}</div>
              {this.state.showComponent ? (
                <div className="input-token2">
                  <InputGroup>
                    <FormControl
                      placeholder="0000 - 0000"
                      aria-describedby="basic-addon2"
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">
                        <img alt="" src={setaDisable} className="Path" />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  {this.state.showComponent ? <this.AlertDismissibleExample option={'erroCodigo'} /> : null}
                  {this.state.showComponent2 ? <this.AlertDismissibleExample option={'erroJa'} /> : null}
                </div>
              ) : (
                  <div className="input-token">
                    <InputGroup>
                      <FormControl
                        placeholder="0000 - 0000"
                        aria-describedby="basic-addon2"
                        value={this.state.value}
                        onChange={this.handleChange}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <img alt="" src={path} className="Path" onClick={() => this.handleAddQueue()} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {this.state.showComponent ? <this.AlertDismissibleExample option={'erroCodigo'} /> : null}
                    {this.state.showComponent2 ? <this.AlertDismissibleExample option={'erroJa'} /> : null}
                  </div>
                )}
            </div>
          </div>
          <div className="results--section__inner"></div>
        </section>
      </div>
    );
  }
}
export default AddQueue;
